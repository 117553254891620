import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { BarChart, XAxis, Cell, LabelList, Bar } from 'recharts'

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px;

  h1 {
    font-size: 64px;
    font-family: 'Space Mono', monospace;
    font-weight: 700;
    color: #e1e1e1;
    text-transform: uppercase;
  }
`

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    color: #e1e1e1;
  }

  p:hover  {
    cursor: pointer;
    border-bottom: 1px solid #e1e1e1;
  }
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  width: 100%;

  input {
    background: #34434e;
    border-radius: 100px 0 0 100px;
    border: 0;
    color: #e1e1e1;
    padding: 8px 32px;
  }

  input:focus {
    outline: none;
  }

  .btn {
    transition: all 0.2s ease;
    background: #4c5dc1;
    padding: 8px 32px;
    border-radius: 0px 100px 100px 0px;
    color: #e1e1e1;
  }

  .btn:hover  {
    transition: all 0.2s ease;
    cursor: pointer;
    opacity: 0.8;
  }
`

const BarWrapper = styled.div`
  background: #34434e;
  border-radius: 16px;
  margin-bottom: 16px;
`

const TeamWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: center;
`

const Team = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #34434e;
  width: 330px;
  margin: 16px;
  padding: 16px 8px;
  border-radius: 16px;

  .scoreWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #e1e1e1;
    border-radius: 8px 8px 0 0;
  }

  .counterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 0 0 8px 8px;
  }

  .teamName {
    font-family: 'Space Mono', monospace;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    margin: 20px 0;
  }

  .button {
    cursor: pointer;
    padding: 4px 28px;
    border: 1px solid #e1e1e1;
    margin: 8px;
    border-radius: 28px;
    font-size: 28px;
    color: #e1e1e1;
    transition: all 0.2s ease;
  }

  .button:hover {
    transition: all 0.2s ease;
    background: #eb3d35;
    border: 1px solid #eb3d35;
    color: #e1e1e1;
  }

  .button::selection {
    background: none;
  }
`

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      teams: [],
      value: '',
    }
  }

  componentDidMount() {
    let data = JSON.parse(localStorage.getItem('teams'))

    if (data) {
      this.setState({
        teams: data,
      })
    }
  }

  onChange = e => {
    const { value, name } = e.target
    this.setState({
      value: value,
    })
  }

  addTeam = e => {
    const newTeam = {
      name: this.state.value,
      score: 0,
    }

    this.setState({
      teams: [...this.state.teams, newTeam],
      value: '',
    })

    localStorage.setItem('teams', JSON.stringify(this.state.teams))

    e.preventDefault()
  }

  incrementScore = i => {
    this.setState({
      teams: this.state.teams.map((v, j) => {
        if (j == i) {
          v.score += 1
        }
        return v
      }),
    })

    localStorage.setItem('teams', JSON.stringify(this.state.teams))
  }

  decrementScore = i => {
    if (this.state.teams[i].score === 0) {
      return
    }
    this.setState({
      teams: this.state.teams.map((v, j) => {
        if (j == i) {
          v.score -= 1
        }
        return v
      }),
    })

    localStorage.setItem('teams', JSON.stringify(this.state.teams))
  }

  resetGame = () => {
    localStorage.clear()
    window.location.reload()
  }

  render() {
    const colors = [
      '#eb3d35',
      '#A45BFF',
      '#ffcd66',
      '#5bffa4',
      '#ffa45b',
      '#5b64ff',
      '#ff5b8d',
      '#EBE1C8',
    ]

    const renderTeams = this.state.teams.map((team, i) => {
      return (
        <Team key={i} style={{ border: `solid 2px ${colors[i]}` }}>
          <div className="scoreWrapper">
            <p className="teamName">{team.name}</p>
          </div>
          <div className="counterWrapper">
            <div className="button" onClick={() => this.incrementScore(i)}>
              +
            </div>
            <div className="button" onClick={() => this.decrementScore(i)}>
              -
            </div>
          </div>
        </Team>
      )
    })

    return (
      <Layout>
        <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
        <Header>
          <h1>
            daresay<span style={{ fontFamily: 'libre franklin' }}>'</span>s
            christmas battle
          </h1>
        </Header>
        {this.state.teams.length === 0 ? null : (
          <BarWrapper>
            <BarChart
              width={1200}
              height={800}
              margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
              data={this.state.teams}
            >
              <XAxis dataKey="never mind me" />
              <Bar dataKey="score" barSize={90}>
                <LabelList dataKey="score" position="top" fill="#e1e1e1" />
                {this.state.teams.map((entry, i) => (
                  <Cell key={`cell-${i}`} fill={colors[i]} />
                ))}
              </Bar>
            </BarChart>
          </BarWrapper>
        )}
        <TeamWrapper>{renderTeams}</TeamWrapper>
        <Form onSubmit={this.addTeam}>
          <input
            placeholder="Team name"
            type="text"
            value={this.state.value}
            onChange={this.onChange}
          />
          <div className="btn" onClick={this.addTeam}>
            Add
          </div>
        </Form>
        <BtnWrapper>
          {this.state.teams.length > 0 ? (
            <p onClick={this.resetGame}>Reset</p>
          ) : null}
        </BtnWrapper>
      </Layout>
    )
  }
}
